@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
	--container: max(calc(50% - 600px), 20px);

	--text: #ffff;
	--bg-color-1: #111111;
	--bg-color-2: #2d3038;

	--neon2: #d13030;
	--lightning: #ffff;
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: Roboto, sans-serif;
	font-weight: 300;
	font-style: normal;
	font-size: 15px;
	color: var(--text);
	background-color: var(--bg-color-1);
	/* overflow: hidden; */

	-webkit-touch-callout: none; /* Safari */
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;

	&._overlay {
		pointer-events: none;
	}
}

button {
	border: none;
	outline: none;
}

input {
	font-size: 14px;
	color: var(--text);
	box-shadow: none;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 300;
	letter-spacing: 0.5px;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}

p {
	color: var(--text);
	margin: 0;
	padding: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.enBold {
	font-family: Benzin-Bold, sans-serif;
}
.enMedium {
	font-family: Benzin-Medium, sans-serif;
}
.hinBold {
	// font-family: hin-Bold, sans-serif;
  font-family: Benzin-Bold, sans-serif;
}
.hinMedium {
	// font-family: hin-Medium, sans-serif;
  font-family: Benzin-Medium, sans-serif;
}

.react-international-phone-input-container {
	position: relative;
	display: flex;
	width: 100% !important;
}

.react-international-phone-input {
	width: 100% !important;
	height: 65px;
	padding-left: 20px;
	border-radius: 0px 50px 50px 0px;
	border-style: solid;
	border-width: 2px 2px 2px 0px;
	border-color: rgba(255, 255, 255, 0.05);
	outline: none;
	background-color: rgba(0, 0, 0, 0.25);
	text-align: start;
	font-size: 20px;
	font-family: Benzin-Medium, sans-serif;
}

.react-international-phone-country-selector-button {
	position: relative;
	width: 70px !important;
	height: 65px;
	border-radius: 50px 0px 0px 50px;
	border-style: solid;
	border-width: 2px 2px 2px 0px;
	border-color: rgba(255, 255, 255, 0.05);
	outline: none;
	background-color: rgba(0, 0, 0, 0.25);
	text-align: center;
	font-size: 10px;
}

.react-international-phone-flag-emoji,
.react-international-phone-country-selector-button__flag-emoji {
	width: 25px;
	height: 25px;
}

.react-international-phone-country-selector-dropdown {
	position: absolute;
	left: 0px;
	width: 100%;
	height: 150px !important;
	overflow-y: scroll;
	backdrop-filter: blur(50px);
	border: 2px solid rgba(255, 255, 255, 0.05) !important;
	outline: none !important;
	list-style: none;
}

.react-international-phone-country-selector-dropdown__list-item {
	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
	gap: 10px;
}

// .react-international-phone-country-selector-button {
// 	border-radius: 7px 0px 0px 7px !important;
// }

// .aks-input[type="text"]:hover,
// .aks-input[type="email"]:hover,
// .react-international-phone-input:hover,
// .react-international-phone-country-selector-button:hover {
// 	border-color: var(--neon2) !important;
// }

// .react-international-phone-country-selector-button:hover {
// 	box-shadow: 0 0 0 2px var(--neon2) !important;
// }

// .aks-input[type="text"]:focus,
// .aks-input[type="email"]:focus,
// .react-international-phone-input:focus,
// .react-international-phone-country-selector-button:focus {
// 	box-shadow: 0 0 0 2px var(--neon2) !important;
// 	border-color: var(--neon2) !important;
// }
// .aks-input[type="text"]:disabled,
// .aks-input[type="email"]:disabled,
// .react-international-phone-input:disabled,
// .react-international-phone-country-selector-button:disabled {
// 	background: #f6f8ff;
// 	cursor: not-allowed;
// 	opacity: 0.9;
// 	border-color: #bbc1e1;
// }

.swiper-slide {
	background-position: center !important;
	background-size: contain !important;
	padding: 50px !important;
  margin: auto 0;
	// width: 300px !important;
	// height: 300px !important;
}

.swiper-button-prev,
.swiper-button-next {
	color: #f10361 !important;
}

.swiper-pagination-bullet-active {
	background-color: #f10361 !important;
}

.swiper-pagination-bullet {
  background-color: #f10361 !important;
}
