.revievs {
	padding-top: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	.title {
		font-size: 14px;
		color: #f19203;
		margin-top: 30px;
    // padding: 0px 35px;
    text-align: center;
		// margin-bottom: 30px;
	}

	.slider {
		position: relative;
		width: 100%;
	}

	.slideContainer {
		display: flex;
		justify-content: center;
		align-items: center;

		video {
			max-width: 320px;
			max-height: 420px;
		}

		&._largeFoto {
			img {
				max-width: 320px !important;
				max-height: 520px !important;
			}
		}
	}

	.line {
		width: 100%;
		height: 3px;
		background-color: #2f2f2f;
		margin-top: 30px;
		// margin-bottom: 30px;
	}

	.steps {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}
	.step {
		margin-bottom: 10px;
		width: fit-content;
		height: 75px;
		width: 75px;
		padding: 0px 30px;
		border-radius: 50px;
		color: #1a1b1b;
		font-size: 24px;
		line-height: 110%;
		background-color: #f10361;
		box-shadow: 0px 0px 10px 2px #f10361;
		display: flex;
		justify-content: center;
		align-items: center;

		&:nth-child(1) {
			margin-top: 50px;
		}
	}

	.stepText {
		margin-bottom: 20px;
		font-size: 14px;
		text-align: center;
		text-transform: uppercase;
		max-width: 320px;
	}
}
