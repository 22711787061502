@font-face {
	font-family: "Benzin-Medium";
	src: url("../../public/fonts/Benzin-Medium.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Benzin-Bold";
	src: url("../../public/fonts/Benzin-Medium.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Hin-Medium";
	src: url("../../public/fonts/NotoSansDevanagari_Condensed-Medium.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Hin-Bold";
	src: url("../../public/fonts/NotoSansDevanagari-Bold.ttf") format("truetype");
	font-display: swap;
}
