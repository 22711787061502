@import "./img.scss";
@import "./fonts.scss";

.container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	min-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 20px;
	background-image: $background;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 400vh;
}

.button {
	width: fit-content;
	height: 70px;
	padding: 0px 30px;
	border-radius: 50px;
	color: var(--text);
	font-size: 26px;
	line-height: 100%;
	background-color: #f10361;
	-webkit-box-shadow: 0px 0px 10px 2px #f10361;
	-moz-box-shadow: 0px 0px 10px 2px #f10361;
	box-shadow: 0px 0px 10px 2px #f10361;
	cursor: pointer;

	&._gray {
		height: 50px;
		font-size: 18px;
		background-color: #252525;
		color: #676767;
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
	}

	&._blue {
		height: 50px;
		font-size: 18px;
		background-color: #0fa9ff;
		color: #fff;
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
	}

	&._small {
		width: 100%;
		max-width: 350px;
		height: 50px;
		font-size: 14px;
		white-space: nowrap;
		padding: 0px 15px;
	}

	&._green {
		background-color: #56ba4d;
	}
	&._orange {
		background-color: #ff5050;
	}
}

.showVideo {
	margin-top: 20px;
	padding: 0px;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid #797979;
	background-color: transparent;
	text-transform: uppercase;
	font-size: 13px;
	color: #797979;
}

.videoPopup {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100dvh;
	background-color: rgba(23, 23, 23, 1);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;

	&._show {
		display: block;
	}

	.closeContainer {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 65px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 20px;
		// backdrop-filter: blur(2px);
		z-index: 100;
	}

	.videoClose {
		position: relative;
		width: 35px;
		height: 35px;
		background-color: #171717;
		border: 2px solid rgb(255, 255, 255);
		border-radius: 50%;
		z-index: 100;

		&:before,
		&:after {
			content: " ";
			position: absolute;
			left: 15px;
			top: 3px;
			height: 25px;
			width: 2px;
			background-color: #d7d7d7;
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}

	.video {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.title {
	width: 100%;
	height: 200px;
	background-image: $configTitle;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.formContainer {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin-bottom: 50px;

	.loginTitle {
		font-size: 20px;
		text-transform: uppercase;
    text-align: center;
	}

	.input {
		width: 100%;
		height: 65px;
		border-radius: 50px;
		border: 2px solid rgba(255, 255, 255, 0.05);
		outline: none;
		background-color: rgba(0, 0, 0, 0.25);
		text-align: center;
		font-size: 20px;
	}
}

.popupContainer {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100dvh;
	backdrop-filter: blur(5px);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;

	.popupContent {
		position: relative;
		max-width: 490px;
		width: calc(100% - 40px);
		height: fit-content;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		background-color: #171717;
		padding: 40px 20px;
		border: 2px solid rgba(255, 255, 255, 0.05);
		border-radius: 20px;
	}

	.popupClose {
		position: absolute;
		right: 5px;
		top: 5px;
		width: 35px;
		height: 35px;
		background-color: #171717;
		border: 2px solid rgba(255, 255, 255, 0.05);
		border-radius: 50%;

		&:before,
		&:after {
			content: " ";
			position: absolute;
			left: 15px;
			top: 3px;
			height: 25px;
			width: 2px;
			background-color: #333;
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}

	.popupTitle {
		position: relative;
		font-size: 14px;
		text-align: center;
		color: #5d5d5d;
		text-transform: uppercase;
		margin-bottom: 10px;
		max-width: 83%;

		&:before {
			content: "";
			position: absolute;
			left: 50%;
			bottom: -10px;
			transform: translateX(-50%);
			width: 80%;
			height: 2px;
			border-radius: 3px;
			background-color: #5d5d5d;
		}
	}

	.popupText {
		position: relative;
		font-size: 13px;
		text-align: center;
		font-family: "Inter", sans-sherif;
		font-weight: 400;
	}
}

.videoBoard {
	width: 100%;
	aspect-ratio: 2/1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #2e2e2e;
	border-radius: 23px;
	background-image: url(../../public/makeDeposit.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	.text {
		text-transform: uppercase;
		font-size: 14px;
		margin-bottom: 10px;
	}

	.buttonPlay {
		width: 60px;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: #f10361;

		.icon {
			width: 25px;
			height: 25px;
			transform: translateX(3px);
			background-image: url(../../public/Play.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
	}
}

.more {
  font-size: 16px;
	margin-top: 30px;
	text-transform: uppercase;
  text-align: center;
}

.arrow {
	width: 60px;
	height: 60px;
	margin-top: 20px;
	background-image: url(../../public/arrow.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	animation-name: move-down;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

@keyframes move-down {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(20px);
	}
	100% {
		transform: translateY(0px);
	}
}
