@import "./fonts.scss";
@import "./img.scss";

.container {
	max-width: 490px;
	width: 100%;
	// height: 645px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.checkbox {
	position: relative;
	width: 150px;
	height: 45px;
	display: flex;
	align-items: center;
	border-radius: 50px;
	overflow: hidden;
	font-size: 16px;
	background-image: $switcher;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	cursor: pointer;
	margin-bottom: 30px;

	.lang {
		position: absolute;
		top: 50%;
		transform: translateY(-38%);
		color: #424242;
		text-transform: uppercase;
		line-height: 100%;
		z-index: 1;
		transition: linear 0.3s;

		&._active {
			color: var(--text);
		}

		&._left {
			left: 15px;
		}

		&._right {
			right: 20px;
		}
	}

	.switcher {
		position: absolute;
		left: 0;
		top: 0;
		width: 50%;
		height: 100%;
		background-color: #414141;
		border-radius: 50px;
		z-index: 0;
		transition: linear 0.3s;

		&._pos1 {
			transform: translateX(0);
		}
		&._pos2 {
			transform: translateX(100%);
		}
	}
}

.title {
	width: 100%;
	font-size: 14px;
	margin-bottom: 5px;
	text-align: center;
}

.coefficients {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 7px;
	font-size: 12px;
	margin-bottom: 10px;

	.coef {
		width: 55px;
		height: 25px;
		background-color: #577cff;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding: 5px;
		border-radius: 11px;
		line-height: 100%;

		&._1 {
			background-color: rgb(52, 180, 255);
		}
		&._2 {
			background-color: #577cff;
		}
		&._3 {
			background-color: #8227ff;
		}

		.loader {
			width: 15px;
			aspect-ratio: 4;
			background: radial-gradient(circle closest-side, #ffffff 90%, #ffffff00) 0 / calc(100% / 3)
				100% space;
			clip-path: inset(0 100% 0 0);
			animation: l1 1s steps(4) infinite;
		}
		@keyframes l1 {
			to {
				clip-path: inset(0 -34% 0 0);
			}
		}
	}
}

.card {
	width: 100%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-bottom: 20px;

	.cardBody {
		width: 100%;
		height: 350px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #1e1e1e;
		border-radius: 50px;
		backdrop-filter: blur(5px);
		padding: 50px 10px;

		.timerContainer {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 0px;
			.tic {
				width: 25px;
				height: 25px;
				background-image: $tic;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}

			.timer {
				position: relative;
				font-size: 20px;
				// margin-top: 5px;
				// margin-bottom: 10px;
				font-family: monospace, sans-serif;
				font-weight: bold;
			}
		}

		.loaderContainer {
			position: relative;
			width: 130px;
			height: 120px;
			margin-bottom: 50px;

			&::before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 60%;
				height: 60%;
				border-radius: 50%;
				background-color: #f10361;
				opacity: 0.5;
				filter: blur(30px);
			}
		}

		.planeContainer {
			position: relative;
			width: 200px;

			.planeAlternative {
				// transform: translate(-1px, 10px);
				width: 100%;
				height: 80px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;

				&._1 {
					display: block;
					background-image: $plane1;
				}
				&._2 {
					display: block;
					background-image: $plane2;
				}
				&._3 {
					display: block;
					background-image: $plane3;
				}
				&._4 {
					display: block;
					background-image: $plane4;
				}

				&._active {
					display: none;
				}

				&::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 60%;
					height: 60%;
					border-radius: 50%;
					background-color: #f10361;
					opacity: 0.5;
					filter: blur(30px);
				}
			}

			.plane1 {
				display: none;
				position: absolute;
				width: 100%;
				height: 90px;
				background-image: $plane1;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				// opacity: 1;

				&._active {
					display: block;
				}
				// animation: hide1 0.2s infinite linear;

				// @keyframes hide1 {
				// 	0% {
				// 		opacity: 1;
				// 	}

				// 	100% {
				// 		opacity: 0;
				// 	}
				// }
			}
			.plane2 {
				display: block;
				position: absolute;
				transform: translate(-1px, 10px);
				width: 100%;
				height: 80px;
				background-image: $plane2;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;

				&._active {
					display: none;
				}

				&::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 60%;
					height: 60%;
					border-radius: 50%;
					background-color: #f10361;
					opacity: 0.5;
					filter: blur(30px);
				}
			}
			.plane3 {
				position: absolute;
				transform: translate(-1px, 10px);
				width: 100%;
				height: 100px;
				background-image: $planeOrange;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;

				&::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 60%;
					height: 60%;
					border-radius: 50%;
					background-color: #ff3535;
					opacity: 0.5;
					filter: blur(30px);
				}
			}
		}

		.loader {
			animation: manMove 2s infinite linear;
			@keyframes manMove {
				0% {
					transform: rotate(0deg);
				}

				100% {
					transform: rotate(360deg);
				}
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.text {
			font-size: 12px;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 15px;
		}

		.info2 {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			.pro {
				width: fit-content;
				background-color: #ffc700;
				color: black;
				padding: 2px 10px;
				border-radius: 5px;
				margin-bottom: 10px;
				span {
					display: inline-block;
					transform: translateY(2px);
				}
			}
			p {
				display: block;
				text-align: center;
				font-size: 10px;

				span {
					color: #ffc700;
				}
			}
		}
	}
}

.coef {
	width: 130px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #2c7e25;
	border-radius: 30px;
	padding: 10px 0px;
	margin-bottom: 0px;

	span {
		font-size: 16px;
	}

	&._2 {
		background-color: #8227ff;
	}
	&._3 {
		background-color: #ff3535;
	}
}

.progressWrapper {
	width: 100%;
	max-width: 400px;
	border-radius: 30px;
	overflow: hidden;
	margin-bottom: 20px;
}

.progress2 {
	position: relative;
	border-radius: 30px;
	background: rgba(0, 0, 0, 0.25);
	height: 30px;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
	display: flex;
	align-items: center;
}

.progress_bar2 {
	position: relative;
	overflow: hidden;
	height: 25px;
	border-radius: 30px;
	// background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
	transition: 0.4s linear;
	transition-property: width, background-color;
}

.lightning {
	position: absolute;
	top: 0;
	left: 0%;
	transform: translateX(-100%);
	width: 100%;
	height: 100%;
	border-radius: 0px 30px 30px 0px;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
	animation: lightningAnimation 1.5s infinite;
}

@keyframes lightningAnimation {
	0% {
		left: 0%;
		transform: translateX(-100%);
	}
	100% {
		left: 100%;
		transform: translateX(0%);
	}
}

.interest {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: rgb(255, 255, 255);
	font-size: 14px;
}

.progress_moved .progress_bar2 {
	background-color: #48e13b;
	transition: linear 0.1s;
}

.warning {
	background-color: #292929;
	border-radius: 20px;
	padding: 20px;
	font-size: 12px;
	text-align: center;
	font-family: Inter, sans-serif;
}

.spanRed {
	color: #f10361;
}
.spanGreen {
	color: #48e13b;
}

.card {
	width: 100%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-bottom: 20px;

	.cardBody {
		width: 100%;
		height: 350px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #1e1e1e;
		border-radius: 50px;
		backdrop-filter: blur(5px);
		padding: 50px 10px;
	}
}

.amazingLoader {
	// background: radial-gradient(#2c2c2c, #1a1a1a);
	width: 100%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-bottom: 20px;

	.containerWrapper {
		width: 100%;
		height: 350px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// border: 1px solid #1e1e1e;
		border-radius: 50px;
		backdrop-filter: blur(5px);
		padding: 50px 10px;
	}

	.container {
		width: 200px;
		height: 200px;
		border-radius: 100%;
		background: linear-gradient(
			165deg,
			#f10361 0%,
			#e50057 40%,
			#c4004b 70%,
			#8f0036 98%,
			#50001f 100%
		);
		position: relative;
	}

	.loader {
		&:before {
			position: absolute;
			left: 0;
			content: "";
			width: 100%;
			height: 100%;
			border-radius: 100%;
			border-bottom: 0 solid rgba(241, 3, 97, 0.05);
			box-shadow: 0 -10px 20px 20px #00000040 inset, 0 -5px 15px 10px #00000050 inset,
				0 -2px 5px #00000080 inset, 0 -3px 2px #000000bb inset, 0 2px 0px #000000, 0 2px 3px #000000,
				0 5px 5px #00000090, 0 10px 15px #00000060, 0 10px 20px 20px #00000040;
			filter: blur(3px);
			animation: 2s rotate linear infinite;
		}
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	.analyzing {
		margin-top: 20px;
		font-size: 16px;
		color: #f10361;

		&:after {
			content: " .";
			animation: dots 1s steps(5, end) infinite;
		}

		@keyframes dots {
			0%,
			20% {
				color: rgba(0, 0, 0, 0);
				text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
			}
			40% {
				color: #f10361;
				text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
			}
			60% {
				text-shadow: 0.25em 0 0 #f10361, 0.5em 0 0 rgba(0, 0, 0, 0);
			}
			80%,
			100% {
				text-shadow: 0.25em 0 0 #f10361, 0.5em 0 0 #f10361;
			}
		}
	}
}

.signalsStoryContainer {
	width: 100%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-bottom: 20px;
	margin-top: 20px;

	.signalsStory {
		width: 100%;
		min-height: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		border: 1px solid #1e1e1e;
		overflow: hidden;
		border-radius: 50px;
		backdrop-filter: blur(5px);
		background-color: #00000040;
		padding-bottom: 50px;

		.title {
			padding: 20px 0px;
			margin: 0px;
		}

		.text {
			color: #f10361;
			text-align: center;
			padding: 0px 20px;
		}
	}

	.signal {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 60px;
		padding: 0px 20px;

		&:nth-child(2n) {
			background-color: #282828;
		}

		.coefficient {
			color: #48e13b;
		}

		.date {
			font-size: 12px;
			color: #a9a9a9;
		}
	}
}
