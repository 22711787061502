.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px 0px;
}

.title {
	font-size: 14px;
	color: #717171;
	margin-bottom: 5px;
}

.lineWrapper {
	width: 100%;
	max-width: 400px;
	height: 40px;
	display: flex;
	align-items: center;
	background-color: #121212;
	border-radius: 10px;
	overflow: hidden;
}

.line {
	width: 100%;
	display: flex;
	align-items: center;
	transition: 0.5s linear;
}

.item {
	min-width: 50%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
  font-family: Inter, sans-serif;
  font-size: 10px;

	span {
		font-family: Inter, sans-serif;
		font-weight: 500;
		font-size: 10px;
		line-height: 110%;
    display: flex;
    align-items: flex-end;
    margin-right: 2px;
    margin-left: 2px;
	}
}

.id {
	color: #f10361;
}
.sum {
	color: #48e13b;
}

.coef {
  font-weight: 900 !important;
}
